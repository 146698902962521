<!--
Similar to <btn-loading-promise>, but the rest call must return a uuid for
progress tracking.

Example:

<btn-loading-progress :click="callback">
	<template #default="{click,progress,current,total,time_elapsed,time_remaining,error}">
		<v-btn @click="click" :loading="progress > 0 && progress < 1">Click me</v-btn>
	</template>
</btn-loading-progress>

-->

<template>
	<progress-tracker
		:uuid="uuid"
		v-on="$listeners"
	>
		<template #default="scope">
			<slot v-bind="{...scope, click: onClick}" />
		</template>
	</progress-tracker>
</template>

<script>
import ProgressTracker from "@/ittijs/components/ProgressTracker";
export default {
	components: {ProgressTracker},
	props: {
		click: Function,
	},
	data(){
		return {
			uuid: null,
		}
	},
	methods: {
		async onClick(){
			if (!this.click) return;
			try {
				this.uuid = null;
				this.uuid = await this.click();
			}
			catch (error) {
				if (this.$listeners.error) {
					this.$emit('error', await error);
				}
				else {
					throw error;
				}
			}
		},
	},
}
</script>