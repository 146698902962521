import ITTIModel from '../../ittijs/ITTIModel';
export default class extends ITTIModel {
  constructor() {
    super('Temperatures');
  }
  async getData(utilityCode, year, month){
    return await this.fetch('getData', {utilityCode,year,month});
  }
  async saveData(utilityCode, year, month, data){
    return await this.fetch('saveData', {utilityCode,year,month}, data);
  }
  async getTemperatures(utilityCode, start, end){
    return await this.fetch('getTemperatures', {utilityCode, start, end});
  }
}