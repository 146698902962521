<template>
	<div>
		<v-toolbar dense rounded>
			<v-text-field :value="value" @input="$emit('input', $event)" hide-details single-line />
			Calculated average: {{ average || '[n/a]' }}
			<v-btn small depressed color="light-blue accent-1" class="ml-2" @click="$emit('input', average)">
				use calculated
			</v-btn>
			<v-btn small depressed color="light-blue accent-1" class="ml-2" @click="selected = []">
				Select none
			</v-btn>
			<v-btn small depressed color="light-blue accent-1" class="ml-2" @click="selected = $refs.calendar.days.map(d=>phpDate('Y-m-d', d))">
				Select all
			</v-btn>
		</v-toolbar>
		<calendar-grid ref="calendar" :start="isoToDate(start)" :end="isoToDate(end)" class="mt-5">
			<template v-slot:day="{isoDate}">
				<v-checkbox v-model="selected" :value="isoDate" hide-details class="my-1" :label="`${isoDate}: ${temps[isoDate]}°C`" />
			</template>
		</calendar-grid>
	</div>
</template>

<script>
import {mean} from 'lodash';
import {isoToDate,phpDate} from '@/ittijs/utils';
import CalendarGrid from '@/ittijs/components/CalendarGrid.vue'
import Model from '../temperatures/Model'

const model = new Model

export default {
	components: {
		CalendarGrid,
	},
	props: [
		'name',
		'start',
		'end',
		'utility',
		'value',
	],
	inject: {
		inputGroups: {
			default: null,
		},
	},
	data(){
		return {
			loading: false,
			selected: [],
			temps: {},
		};
	},
	computed: {
		average(){
			const vals = [];
			for (const d of this.selected) {
				if (typeof this.temps[d] !== 'undefined') {
					const val = parseFloat(this.temps[d]);
					if (!isNaN(val)) vals.push(val);
				}
			}
			const avg = mean(vals);
			return isNaN(avg) ? null : avg.toFixed(5);
		},
	},
	created () {
		this.inputGroups && this.inputGroups.regInput('edit', this);
	},
	mounted(){
		this.loadTemps();
	},
	activated(){
		this.loadTemps();
	},
	beforeDestroy () {
		this.inputGroups && this.inputGroups.unregInput('edit', this);
	},
	watch: {
		start(){
			this.loadTemps();
		},
		end(){
			this.loadTemps();
		},
	},
	methods: {
		async loadTemps(){
			if (this.loading) return;
			this.loading = true;
			const res = await model.getTemperatures(this.utility, this.start, this.end);
			this.temps = res.temperatures || {};
			this.loading = false;
		},
		isoToDate,
		phpDate,
	},
}
</script>