<template>
	<component :is="component"
		v-bind="$attrs"
		v-on="$listeners"
		:callback="()=>restService()"
		@ready="ready"
	>
		<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
			<slot :name="slot" v-bind="scope"/>
		</template>
	</component>
</template>

<script>
import BtnProgress from "@/ittijs/components/BtnProgress";
import BtnProgressConfirm from "@/ittijs/components/BtnProgressConfirm";
/**
 * Сваляне на файл през DownloadCache.
 */
export default {
	components: {BtnProgress,BtnProgressConfirm},
	props: {
		restService: Function,
	},
	computed: {
		component(){
			return this.$scopedSlots["confirm-content"] ? 'btn-progress-confirm' : 'btn-progress';
		},
	},
	methods: {
		ready(url) {
			window.open(url, '_blank');
		},
	},
}
</script>