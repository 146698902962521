<template>
	<div>
		<template v-if="!imodel.loaded"><v-progress-linear indeterminate /></template>
		<template v-else>
			<quick-search-set :filter-group.sync="filterQuick">
				<v-sheet rounded elevation="2" class="ma-2 pa-1 d-flex align-center">

					<span class="mx-1">Дата между</span>
					<quick-search class="ma-1" style="max-width: 200px;" field="date" :op="FilterOps.GREATER_EQUAL" no-hint />
					<span class="mx-1">и</span>
					<quick-search class="ma-1" style="max-width: 200px;" field="date" :op="FilterOps.LOWER_OR_EQUAL" no-hint />

					<v-divider vertical class="mx-2" />

					<field-label name="sql_is_not_processed" />
					<quick-search class="ma-1" style="max-width: 200px;" field="sql_is_not_processed" :op="FilterOps.EQUALS" input-type="btn-toggle" />

					<v-divider vertical class="mx-2" />

					<field-label name="sql_is_not_exported" />
					<quick-search class="ma-1" style="max-width: 200px;" field="sql_is_not_exported" :op="FilterOps.EQUALS" input-type="btn-toggle" />

					<v-divider vertical class="mx-2" />

					<v-spacer/>

					<download-btn-with-progress
						:rest-service="downloadExport"
						:button-props="{
							color: 'primary',
							small: false,
						}"
					>
						<template #button-content>
							Експорт за ВиК
						</template>
					</download-btn-with-progress>

				</v-sheet>
			</quick-search-set>
			<Search @filter="filter = $event" :defaults="defaultSearch" />
			<v-toolbar flat>
				<v-toolbar-title>{{ imodel.Title }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<list
				ref="list"
				headers="id,imot_N,date,client_name,address,created_by,processed_by,sql_is_exported"
				:filter="filter2"
				@edit="onEdit"
				hide-delete hide-select
				sort-by="id"
				sort-desc
				hide-export
			/>
			<TalonPopupEdit
				ref="editPopup"
				:id="editId"
				@reload="onReload"
			/>
		</template>
	</div>
</template>

<script>
import Model from "./Model";
import List from "@/ittijs/List";
import Search from "@/ittijs/Search";
import SearchInput from '@/ittijs/Inputs/SearchInput.vue';
import IndexMixin from "@/ittijs/IndexMixin";
import EditInput from '@/ittijs/Inputs/EditInput.vue';
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import {cloneDeep} from "lodash";
import AvgTempCalc from "@/views/clients_screen/AvgTempCalc";
import EditColumn from "@/ittijs/EditColumn";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Vodomeri from "@/ittijs/components/TalonPlombi/Vodomeri.vue";
import TalonPopupEdit from "@/views/talon_plombi/TalonPopupEdit.vue";
import EditPopup from "@/ittijs/components/EditPopup.vue";
import QuickSearchSet from "@/ittijs/QuickSearchSet.vue";
import QuickSearch from "@/ittijs/QuickSearch.vue";
import {FilterOps} from "@/ittijs/ITTIModel";
import FieldLabel from "@/ittijs/components/FieldLabel.vue";
import DownloadBtnWithProgress from "@/ittijs/components/DownloadBtnWithProgress.vue";

/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable vue/no-unused-components */

const imodel = ITTIModelProvider.getModel(Model);

export default {

	mixins: [
		IndexMixin,
		SelectedUtilityMixin,
	],

	components: {
		DownloadBtnWithProgress,
		FieldLabel,
		QuickSearch,
		QuickSearchSet,
		EditPopup,
		TalonPopupEdit,
		Vodomeri,
		EditColumn,
		AvgTempCalc,
		Search,
		List,
		SearchInput,
		EditInput,
	},

	data(){
		return {
			defaultSearch: {
				rules:[],
				groups:{
					g1:{rules:{}
					}
				},
			},
			filter: {},
			filterQuick: {},
			imodel: imodel,
			editId: null,
		};
	},

	computed: {
		FilterOps() {
			return FilterOps
		},
		filter2: function(){
			if (!this.selectedUtility) return this.filter;
			const f2 = cloneDeep(this.filter);
			f2.groups = {
				quick: this.filterQuick,
				constrains: {
					rules: {
						utility_code: {
							field: 'utility_code',
							op: 'eq',
							value: this.selectedUtility,
						}
					}
				}
			};
			return f2;
		},
	},

	methods: {
		onEdit(record){
			this.editId = record.id;
			this.$refs.editPopup.open();
		},
		onReload(){
			this.$refs.list.refresh();
		},
		downloadExport(){
			return imodel.fetch('downloadExport', {filter: this.filter2}, {});
		},
	},

	provide: function () {
		return {
			imodel,
		};
	},

};
</script>