<!--
Simplified version of BtnProgressConfirm, without the confirm dialog
-->

<template>
	<btn-loading-progress
		:click="()=>callback()"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<template #default="{click, progress, time_remaining, error}">
			<v-btn
				v-bind="{...buttonDefaultProps, ...buttonProps}"
				@click="click"
				:loading="!error && typeof progress === 'number' && progress < 1"
				:disabled="!error && typeof progress === 'number' && progress < 1"
			>
				<!-- keep the same slot names as BtnProgressConfirm -->
				<slot name="button-content">{{ buttonLabel }}</slot>
				<template #loader>
					<span>
						<v-progress-circular :value="progress * 100" size="22" />
						{{ time_remaining }}
					</span>
				</template>
			</v-btn>
		</template>
	</btn-loading-progress>
</template>

<script>
import BtnLoadingProgress from "@/ittijs/components/BtnLoadingProgress";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
export default {
	components: {
		BtnLoadingProgress,
	},
	mixins: [ThrowMeMixin],
	props: {
		buttonLabel: String,
		buttonProps: Object,
		callback: Function,
	},
	computed: {
		buttonDefaultProps: ()=>({
			small: true,
		}),
	},
}
</script>