export default {

  data: ()=>({
    id: null,
    active: true,
  }),
  
  computed: {
    isEdit(){
      return this.id !== null;
    },
    isEditExisting(){
      return this.id > 0;
    },
    isInsert() {
      return this.id !==null && !(this.id > 0);
    }
  },

  activated(){
    this.active = true;
    if (this.$route.params.id) {
      this.imodel.fieldsAsync.then(
        function () {
          this.edit(this.$route.params.id);
        }.bind(this)
      );
    }
    else {
      this.cancelEdit();
    }
  },

  deactivated(){
    this.active = false;
  },

  mounted() {
    if (this.$route.params.id) {
      this.imodel.fieldsAsync.then(
        function () {
          this.edit(this.$route.params.id);
        }.bind(this)
      );
    }
  },

  watch: {
    $route(to) {
      if (!this.active) return;
      if (to.params.id) {
        this.edit(to.params.id);
      } else {
        this.cancelEdit();
        if (this.$refs.list) {
          this.$refs.list.refresh();
        }
        else {
          console.info('no this.$refs.list, no refresh');
        }
      }
    },
  },

  methods: {
    
    onAdd() {
      this.$router.push({ params: { id: "new" } });
    },

    onEdit(param) {
      if (param.id != this.id) {
        this.$router.push({ params: { id: param.id } });
      }
    },

    onSave(params) {
      if (params.id > 0 && !(this.$route.params.id > 0)) {
        this.$router.replace({ params: { id: params.id } });
      }
    },

    onSaveClose() {
      /* eslint-disable @typescript-eslint/no-empty-function */
      this.$router.push({ params: { id: null } }).catch(()=>{});
    },

    onEditCancel() {
      /* eslint-disable @typescript-eslint/no-empty-function */
      this.$router.push({ params: { id: null } }).catch(()=>{});
    },

    edit(id) {
      this.id = id;
    },

    cancelEdit() {
      this.id = null;
    },

  },

}