<template>
  <div class="grid" v-bind="$attrs">
    <div class="head">Пон</div>
    <div class="head">Вто</div>
    <div class="head">Сря</div>
    <div class="head">Чет</div>
    <div class="head">Пет</div>
    <div class="head">Съб</div>
    <div class="head">Нед</div>
    <div :colspan="offsetStart"></div>
    <div v-for="day in days" :key="day.getTime()">
      <slot name="day" v-bind:day="day" v-bind:isoDate="phpDate('Y-m-d', day)" />
    </div>
    <div :colspan="offsetEnd"></div>
  </div>
</template>

<script>
import { phpDate } from '../utils';

export default {
  props: {

    // either these two:
    year: Number,
    month: Number,
    
    // or these two:
    start: Date,
    end: Date,

  },
  computed: {
    date(){
      if (this.start instanceof Date) {
        return this.start;
      }
      return new Date(this.year, this.month-1, 1, 0, 0, 0, 0);
    },
    offsetStart(){ // how many days from the first week are empty
      return (this.date.getDay() + 6) % 7;
    },
    offsetEnd(){ // how many days from the next month are shown
      if (this.end instanceof Date) {
        return 7 - ((this.end.getDay() + 6) % 7);
      }
      const d = this.date;
      d.setMonth(d.getMonth()+1);
      return 7 - ((d.getDay() + 6) % 7);
    },
    days(){
      if (this.year > 0 && this.month > 0) {
        return this.daysYearMonth;
      }
      if (this.start instanceof Date && this.end instanceof Date && this.start.getTime() < this.end.getTime()) {
        return this.daysStartEnd;
      }
      return [];
    },
    daysYearMonth(){
      const date = new Date(this.date);
      const result = [];
      while (date.getFullYear() == this.year && date.getMonth()+1 == this.month) {
        result.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return result;
    },
    daysStartEnd(){
      const date = new Date(this.start);
      const result = [];
      while (date.getTime() <= this.end.getTime()) {
        result.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return result;
    },
  },
  methods: {
    phpDate,
  },
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-flow: row;
  gap: 1px;
  background-color: silver;
  border: 1px solid silver;
}
.grid::v-deep > * {
  background-color: white;
}
.grid::v-deep > .head {
  font-weight: bold;
  text-align: center;
}
.grid::v-deep > [colspan="0"] { display: none; }
.grid::v-deep > [colspan="2"] { grid-column: span 2; }
.grid::v-deep > [colspan="3"] { grid-column: span 3; }
.grid::v-deep > [colspan="4"] { grid-column: span 4; }
.grid::v-deep > [colspan="5"] { grid-column: span 5; }
.grid::v-deep > [colspan="6"] { grid-column: span 6; }
</style>