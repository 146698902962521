<template>
	<my-dialog
		@open="onOpen"
		v-model="dialog"
		persistent
		title="Талон за пломбиране на водомери"
		max-width="900"
		@close="$emit('close')"
	>
		<template #activator="{attrs,on}">
			<slot name="activator" v-bind="{attrs,on}"></slot>
		</template>
		<data-loader :callback="()=>imodel.fetch('getRow', {id})"
			:data.sync="data"
		>
			<template #loading>
				<v-progress-linear indeterminate />
			</template>
			<Talon :data.sync="data">
				<template #container-top="{data}">
					<v-row dense>
						<v-col>
							<v-alert type="info" text outlined dense class="mb-0">
								<div v-if="parseInt(data.created_by) > 0">
									Създаден от <strong><user-info :id="parseInt(data.created_by)"/></strong>
									(<date-time-format :datetime="data.created_time" />)
								</div>
								<div v-if="parseInt(data.processed_by) > 0">
									Обработен от <strong><user-info :id="parseInt(data.processed_by)"/></strong>
									(<date-time-format :datetime="data.processed_time" />)
								</div>
								<div v-else>
									Необработен
								</div>
								<div v-if="parseInt(data.exported_by) > 0">
									Експортиран от <strong><user-info :id="parseInt(data.exported_by)"/></strong>
									(<date-time-format :datetime="data.exported_time" />)
								</div>
								<div v-else>
									Не е експортиран
								</div>
							</v-alert>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col class="d-flex">
							<v-checkbox
								dense hide-details="auto"
								label="Маркирай като обработен"
								v-model="data.isProcessed"
							/>
						</v-col>
					</v-row>
					<v-row dense v-if="data.documentPDF">
						<v-col>
							Генериран PDF файл:
							<a :href="data.documentPDF.url" target="_blank">
								{{ data.documentPDF.name }}
								<span>({{ formatBytes(data.documentPDF.size) }})</span>
							</a>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col>
							<v-alert color="grey darken-1" text dense class="mb-0">
								Талон
							</v-alert>
						</v-col>
					</v-row>
				</template>
			</Talon>
		</data-loader>
		<template #actions>
			<v-spacer/>
			<edit-buttons
				:show-delete="true"
				@save="doSaveClose"
				@delete="onDelete"
				@close="doClose"
				:disabled="editLoading"
			/>
			<ConfirmDlg ref="deleteConfirm" label-confirm="Изтрий" color-confirm="red darken-1" icon-confirm="mdi-delete">
				Моля потвърдете изтриването на записа
			</ConfirmDlg>
		</template>
	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import Talon from "@/ittijs/components/TalonPlombi/Talon.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import EditButtons from "@/ittijs/EditButtons.vue";
import ConfirmDlg from "@/ittijs/ConfirmDialog.vue";
import UserInfo from "@/ittijs/components/UserInfo.vue";
import DateTimeFormat from "@/ittijs/components/DateTimeFormat.vue";
import {formatBytes} from "../../ittijs/utils";

export default {
	components : {DateTimeFormat, UserInfo, ConfirmDlg, EditButtons, DataLoader, Talon, MyDialog},
	mixins: [
		IModelMixin,
		ThrowMeMixin,
	],
	props: {
		id: {},
	},
	data(){
		return {
			data: null,
			dialog: false,
			isFormValid: true,
			editLoading: false,
		}
	},
	methods: {
		formatBytes,
		open(){
			this.dialog = true;
		},
		onOpen(){
			this.data = null;
		},
		async doSaveClose(){
			try {
				this.editLoading = true;
				await this.imodel.fetch('saveRow', {id : this.id}, {data: this.data});
				this.dialog = false;
				this.$emit('reload');
			}
			catch (e) {
				this.throwMe(e);
			}
			finally {
				this.editLoading = false;
			}
		},
		async onDelete(){
			if (! await this.$refs.deleteConfirm.open()) {
				return;
			}
			try {
				this.editLoading = true;
				await this.imodel.fetch('deleteRow', {}, {id : this.id});
				this.dialog = false;
				this.$emit('reload');
			}
			catch (e) {
				this.throwMe(e);
			}
			finally {
				this.editLoading = false;
			}
		},
		doClose(){
			this.dialog = false;
		},
	},
}
</script>