export default {
	inject: {
		throwError: {
			default: (err) => {
				throw err;
			}
		},
	},
	methods: {
		throwMe(res){
			if (res.status==='ERROR') {
				if (res.errors && res.errors.form && res.errors.form.length > 0) {
					this.throwError(res.errors.form[0]);
					return;
				}
			}
			this.throwError(res);
		},
	},
}