<!--
Higher level component that combines a button that starts an async operation
(optionally confirmed with a dialog) and then tracks the progress of that
operation.
-->

<template>
	<btn-loading-progress
		:click="()=>callback()"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<template #default="{click, progress, time_remaining, error}">
			<confirm-dialog @confirm="click">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="{...buttonDefaultProps, ...buttonProps, ...attrs}"
						v-on="on"
						:loading="!error && typeof progress === 'number' && progress < 1"
						:disabled="!error && typeof progress === 'number' && progress < 1"
					>
						<slot name="button-content">{{ buttonLabel }}</slot>
						<template #loader>
							<span>
								<v-progress-circular :value="progress * 100" size="22" />
								{{ time_remaining }}
							</span>
						</template>
					</v-btn>
				</template>
				<slot name="confirm-content" />
			</confirm-dialog>
		</template>
	</btn-loading-progress>
</template>

<script>
import BtnLoadingProgress from "@/ittijs/components/BtnLoadingProgress";
import ConfirmDialog from "@/ittijs/ConfirmDialog";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
export default {
	components: {
		ConfirmDialog,
		BtnLoadingProgress,
	},
	mixins: [ThrowMeMixin],
	props: {
		buttonLabel: String,
		buttonProps: Object,
		callback: Function,
	},
	computed: {
		buttonDefaultProps: ()=>({
			small: true,
		}),
	},
}
</script>